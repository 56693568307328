<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th v-for="(field, n) in fields" :key="n" scope="col">
          {{ normalizeField(field) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="busy">
        <td :colspan="fields.length">
          <div class="text-center text-primary my-2">
              <span aria-hidden="true" class="align-middle spinner-border">
              </span>
          </div>
        </td>
      </tr>
      <tr v-else-if="items.length == 0">
        <td :colspan="fields.length">
          <div class="text-center my-2">Não há registros que correspondam à sua solicitação</div>
        </td>
      </tr>
      <tr v-else v-for="(item, n) in items" :key="n">
        <td v-for="(field, i) in fields" :key="i"  @click="$emit('row-click', item)" :class="clickable ? 'clickable' : ''">
          <template v-if="$scopedSlots[`cell(${getFieldKey(field)})`]">
            <slot :name="`cell(${getFieldKey(field)})`" :data="getValue(item, field)" :item="item"></slot>
          </template>
          <template v-else>{{ getValue(item, field) }}</template>
        </td>
        <td v-if="$scopedSlots['actions']">
          <slot name="actions" :data="item"></slot>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      q: '',
    };
  },
  mounted() {
  },
  methods: {
    rowClick(field, item) {
      if (field.key !== 'actions') {
        this.$root.$emit('row-click', item);
      }
    },
    getFieldKey(field) {
      return field.key ?? field;
    },
    normalizeField(field) {
      let normalizedField = field;
      if (typeof field === 'string') {
        normalizedField = this.$tc(`app.${field}`, 1);
      } else if (field.label) {
        normalizedField = field.label;
      } else {
        normalizedField = this.$tc(`app.${field.key}`, 1);
      }
      return normalizedField;
    },
    getValue(item, field) {
      const key = this.getFieldKey(field);
      const keys = key.split('.'); // Suporte para chaves aninhadas
      const value = keys.reduce((acc, k) => {
        if (acc && acc[k] !== undefined) {
          return acc[k];
        }
        return undefined;
      }, item);

      if (field.formatter) {
        return field.formatter(value);
      }

      return value;
    },
  },
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0 12px;
  border-collapse: separate;

  thead {
    th {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      border-bottom: none;
    }
  }

  tbody {
    tr {

      td {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #E5E5E5;
        padding: 16px;
        .material-symbols-outlined {
          vertical-align: text-bottom;
        }
      }

      td:first-child {
        border-left: 1px solid #E5E5E5;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-right: 1px solid #E5E5E5;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}
.clickable {
  cursor: pointer;
}
</style>
